import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { BsFillPencilFill } from "react-icons/bs";
import { TbReportAnalytics } from "react-icons/tb";
import { colors } from "../../Config/Colors";
import { MdEmail, MdLocationCity } from "react-icons/md";
import { BsTelephone } from "react-icons/bs";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { FaRecycle } from "react-icons/fa";
import Confirm from "../Utils/Confirm";

const CustomFirmList = ({ data, distributeFirmQuota }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFirm, setSelectedFirm] = useState(null);

  const handleOpenModal = (firm) => {
    setSelectedFirm(firm);
    setIsModalOpen(true);
  };

  const handleCloseModal = (confirmed) => {
    if (confirmed && selectedFirm) {
      distributeFirmQuota(selectedFirm.firmName);
    }
    setIsModalOpen(false);
  };

  return (
    <div className="grid grid-cols-2 max-lg:grid-cols-1 gap-4">
      {data.map((firm) => (
        <div
          key={firm._id}
          className="bg-white rounded-lg shadow-md hover:shadow-lg transition-all duration-300 overflow-hidden"
        >
          {/* Başlık ve Aksiyon Butonları */}
          <div
            className={`flex justify-between items-center p-4 border-b ${colors.background.main}`}
          >
            <div className="flex items-center gap-3 flex-wrap">
              <h3 className="text-xl font-semibold text-gray-800">
                {firm.firmName}
                {firm.storage && (
                  <span className="ml-2 text-lg font-medium text-gray-500">
                    (Depo Firması)
                  </span>
                )}
              </h3>
              <div className="flex gap-2 items-center">
                <span
                  className={`
                    px-3 py-1 rounded-full text-xl font-semibold
                    ${colors.background.light} ${colors.primary.text}
                  `}
                >
                  {firm.bayserNo}
                </span>
                <span
                  className={`
                    px-3 py-1 rounded-full text-xl font-semibold
                    ${
                      firm.isActive
                        ? `${colors.status.success.bg} ${colors.status.success.text}`
                        : `${colors.status.inactive.bg} ${colors.status.inactive.text}`
                    }`}
                >
                  {firm.isActive ? "Aktif" : "Pasif"}
                </span>
              </div>
              <button
                onClick={() => handleOpenModal(firm)}
                className="flex items-center text-lg gap-2 px-4 py-2 rounded-lg text-white bg-blue-600 hover:bg-blue-200 transition-all duration-200 font-medium"
              >
                <FaRecycle
                  className={`w-5 h-5 ${
                    firm.quotaDistributing ? "animate-spin" : ""
                  }`}
                />
                Kota {firm.quotaDistributing ? "Dağıtılıyor" : "Dağıt"}
              </button>
            </div>

            <div className="flex gap-2">
              <NavLink
                to={`/Anasayfa/Firma/Düzenle/Bilgi`}
                state={{
                  id: firm._id,
                  firmName: firm.firmName,
                  bayserNo: firm.bayserNo,
                  createdInfo: firm.createdInfo,
                  updatedInfo: firm.updatedInfo,
                }}
              >
                <button
                  className={`p-2 rounded-lg ${colors.primary.text} ${colors.primary.hover} hover:text-white transition-all duration-300`}
                >
                  <BsFillPencilFill className="w-6 h-6" />
                </button>
              </NavLink>
              <NavLink
                to={`/Anasayfa/Firma/Bilgi/Genel`}
                state={{
                  id: firm._id,
                  firmName: firm.firmName,
                  bayserNo: firm.bayserNo,
                  isActive: firm.isActive,
                  createdInfo: firm.createdInfo,
                  updatedInfo: firm.updatedInfo,
                }}
              >
                <button
                  className={`p-2 rounded-lg ${colors.primary.text} ${colors.primary.hover} hover:text-white transition-all duration-300`}
                >
                  <TbReportAnalytics className="w-6 h-6" />
                </button>
              </NavLink>
            </div>
          </div>

          {/* Firma Detayları */}
          <div className="p-4 grid grid-cols-2 gap-4">
            {/* İletişim Bilgileri */}
            <div>
              <div
                className={`${colors.background.light} p-4 rounded-lg h-full`}
              >
                <h4
                  className={`${colors.primary.text} font-medium mb-3 text-xl`}
                >
                  İletişim Bilgileri
                </h4>
                <div className="grid grid-cols-1 gap-3">
                  <div
                    className={`${colors.background.main} p-3 rounded-lg shadow-sm border ${colors.border.light}`}
                  >
                    <div className="flex items-center gap-2">
                      <MdEmail className={`${colors.text.tertiary} w-5 h-5`} />
                      <span className={`text-lg ${colors.text.tertiary}`}>
                        Email
                      </span>
                    </div>
                    <p
                      className={`text-lg mt-2 font-semibold ${colors.primary.text}`}
                    >
                      {firm.email}
                    </p>
                  </div>
                  <div
                    className={`${colors.background.main} p-3 rounded-lg shadow-sm border ${colors.border.light}`}
                  >
                    <div className="flex items-center gap-2">
                      <BsTelephone
                        className={`${colors.text.tertiary} w-5 h-5`}
                      />
                      <span className={`text-lg ${colors.text.tertiary}`}>
                        Telefon
                      </span>
                    </div>
                    <p
                      className={`text-lg mt-2 font-semibold ${colors.primary.text}`}
                    >
                      {firm.tel}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Adres Bilgileri */}
            <div>
              <div
                className={`${colors.background.light} p-4 rounded-lg h-full`}
              >
                <h4
                  className={`${colors.primary.text} font-medium mb-3 text-xl`}
                >
                  Adres Bilgileri
                </h4>
                <div className="grid grid-cols-1 gap-3">
                  <div
                    className={`${colors.background.main} p-3 rounded-lg shadow-sm border ${colors.border.light}`}
                  >
                    <div className="flex items-center gap-2">
                      <MdLocationCity
                        className={`${colors.text.tertiary} w-5 h-5`}
                      />
                      <span className={`text-lg ${colors.text.tertiary}`}>
                        İl
                      </span>
                    </div>
                    <p
                      className={`text-lg mt-2 font-semibold ${colors.primary.text}`}
                    >
                      {firm.address.city}
                    </p>
                  </div>
                  <div
                    className={`${colors.background.main} p-3 rounded-lg shadow-sm border ${colors.border.light}`}
                  >
                    <div className="flex items-center gap-2">
                      <HiOutlineBuildingOffice2
                        className={`${colors.text.tertiary} w-5 h-5`}
                      />
                      <span className={`text-lg ${colors.text.tertiary}`}>
                        İlçe
                      </span>
                    </div>
                    <p
                      className={`text-lg mt-2 font-semibold ${colors.primary.text}`}
                    >
                      {firm.address.town}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <Confirm
        showConfirmModal={isModalOpen}
        handleCloseModel={handleCloseModal}
        infoText={`${selectedFirm?.firmName} için kota dağıtmak istediğinize emin misiniz?`}
      />
    </div>
  );
};

export default CustomFirmList;
