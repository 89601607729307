import { useState, useEffect } from "react";
import useAuth from "../../Hooks/useAuth";
import { useNavigate } from "react-router-dom";
import Alerts from "../../Components/Utils/Alert";
import { useAddUserMutation } from "../../store";
import Confirm from "../../Components/Utils/Confirm";
import Loader from "../../Components/Utils/Loader";
import UserForm from "../../Forms/UserForm";

function CreateUserPage() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const token = auth.accessToken;
  const [alert, setAlert] = useState(0); // 1- success 2-error 3-warning
  const [message, setMessage] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const inputFieldName = {
    role: "Rol",
    firmName: "Firma İsmi",
    name: "İsim",
    lastName: "Soyisim",
    email: "Email",
    tel: "Telefon",
  };
  const [infoText, setInfoText] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [add, resultAdd] = useAddUserMutation();

  const [input, setInput] = useState({
    role: "",
    firmName: "",
    name: "",
    lastName: "",
    email: "",
    tel: "",
  });

  useEffect(() => {
    if (alert !== 0) {
      setTimeout(() => {
        if (alert === 1) {
          setAlert(0);
          setMessage("");
          navigate(-1);
        }
        setAlert(0);
        setMessage("");
      }, 2000);
    }
  }, [alert]);

  const handleApiResponse = (apiResponse, successMessage) => {
    setConfirm(false);
    if (apiResponse.isError) {
      setAlert(2);
      setMessage(resultAdd.error.data?.message);
    }
    if (apiResponse.isSuccess) {
      setAlert(1);
      setMessage(successMessage);
    }
  };

  useEffect(() => {
    handleApiResponse(resultAdd, "Kullanıcı oluşturuldu !");
  }, [resultAdd.isSuccess, resultAdd.isError]);

  const handleCloseModel = (bool) => {
    if (bool) {
      add({
        input: {
          ...input,
          role:
            input.role === "Plasiyer"
              ? "playmaker"
              : input.role === "Yönetici"
              ? "management"
              : input.role === "Muhasebe"
              ? "accounting"
              : input.role === "Müşteri"
              ? "customer"
              : input.role === "Üretim"
              ? "manufacturer"
              : "",
        },
        token,
      });
      setConfirm(true);
      setShowConfirmModal(false);
    } else {
      setShowConfirmModal(false);
    }
  };

  return (
    <div>
      {alert !== 0 && (
        <div
          className="fixed z-50 left-1/2 top-0
    -translate-x-1/2"
        >
          <Alerts AlertChoice={alert} message={message} />
        </div>
      )}

      <Confirm
        input={input}
        inputFieldName={inputFieldName}
        infoText={infoText}
        handleCloseModel={handleCloseModel}
        showConfirmModal={showConfirmModal}
      />

      {/*
      Device information.
      */}
      {resultAdd.isLoading || confirm ? (
        <div className=" flex w-full h-full justify-center items-center">
          <Loader Visible={true} Width={140} Height={140} Color={"#93C6E7"} />
        </div>
      ) : (
        <UserForm
          handleOpenModal={(values) => {
            setInfoText("Kullanıcıyı oluşturmak istediğinize emin misiniz ?");
            setInput(values);
            setShowConfirmModal(true);
          }}
          initialFValues={{
            role: "",
            firmName: "",
            name: "",
            lastName: "",
            email: "",
            tel: "",
          }}
          token={token}
        />
      )}
    </div>
  );
}

export default CreateUserPage;
