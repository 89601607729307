import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import URL from "../url";
import { createRequestData } from "./utils";

const BillsApi = createApi({
  reducerPath: "bills",
  baseQuery: fetchBaseQuery({
    baseUrl: `${URL.url}`,
    fetchFn: async (...args) => {
      // REMOVE FOR PRODUCTION
      return fetch(...args);
    },
  }),
  tagTypes: "Bills",
  endpoints(builder) {
    return {
      createBills: builder.mutation({
        query: (data) => {
          const { token, ...filteredData } = data;
          const requestData = createRequestData({
            ...filteredData,
          });
          return {
            url: `/bills/createBill`,
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: {
              ...requestData,
            },
          };
        },
      }),
      controllBills: builder.mutation({
        query: (data) => {
          const { token, ...filteredData } = data;
          const requestData = createRequestData({
            ...filteredData,
          });
          return {
            url: `/bills/controllBill`,
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: {
              ...requestData,
            },
          };
        },
      }),
      deleteBill: builder.mutation({
        query: (Object) => {
          return {
            url: `/bills/${Object.id}`,
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${Object.token}`,
            },
          };
        },
      }),
      getBills: builder.query({
        query: (data) => {
          const { token, ...filteredData } = data;
          const requestData = createRequestData({
            ...filteredData,
          });
          return {
            url: `/bills/getMyBills`,
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: {
              ...requestData,
            },
          };
        },
      }),
      fetchBills: builder.query({
        query: (token) => {
          return {
            url: "/bills",
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
      }),
    };
  },
});

export const {
  useFetchBillsQuery,
  useCreateBillsMutation,
  useGetBillsQuery,
  useControllBillsMutation,
  useDeleteBillMutation,
} = BillsApi;
export { BillsApi };
