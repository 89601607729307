import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import URL from "../url";
import { createRequestData } from "./utils";

const firmApi = createApi({
  reducerPath: "firm",
  baseQuery: fetchBaseQuery({
    baseUrl: `${URL.url}`,
    fetchFn: async (...args) => {
      // REMOVE FOR PRODUCTION
      return fetch(...args);
    },
  }),
  endpoints(builder) {
    return {
      addFirm: builder.mutation({
        query: (data) => {
          const { token, ...filteredData } = data;
          const requestData = createRequestData({
            ...filteredData,
          });
          return {
            url: `/firms/createFirm`,
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: {
              ...requestData,
            },
          };
        },
        invalidatesTags: ["Firm", "Sync", "Device"],
      }),
      updateFirmInfo: builder.mutation({
        query: (input) => {
          return {
            url: `/firms/${input._id}`,
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${input.token}`,
            },
            body: {
              ...input,
            },
          };
        },
        invalidatesTags: ["Firm"],
      }),
      updateFirmSync: builder.mutation({
        query: (data) => {
          const { token, ...filteredData } = data;
          const requestData = createRequestData({
            ...filteredData,
          });
          return {
            url: `/firms/updateSync`,
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: {
              ...requestData,
            },
          };
        },
        invalidatesTags: ["Sync", "Device", "Firm"],
      }),
      updateFirmProductQuota: builder.mutation({
        query: (input) => {
          return {
            url: `/firms/updateFirmProductQuota/${input.id}`,
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${input.token}`,
            },
            body: {
              ...input,
            },
          };
        },
        invalidatesTags: ["Sync", "Device", "Firm"],
      }),
      updateTakeBackFirmProductQuota: builder.mutation({
        query: (data) => {
          const { token, ...filteredData } = data;
          const requestData = createRequestData({
            ...filteredData,
          });
          return {
            url: `/firms/takeBackFirmProductQuota`,
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: {
              ...requestData,
            },
          };
        },
        invalidatesTags: ["Sync", "Device", "Firm"],
      }),
      updateGiveFirmProductQuota: builder.mutation({
        query: (data) => {
          const { token, ...filteredData } = data;
          const requestData = createRequestData({
            ...filteredData,
          });
          return {
            url: `/firms/giveFirmProductQuota`,
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: {
              ...requestData,
            },
          };
        },
        invalidatesTags: ["Sync", "Device", "Firm"],
      }),
      divideQuota: builder.mutation({
        query: (input) => {
          return {
            url: `/firms/divideQuota/${input.id}`,
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${input.token}`,
            },
            body: {
              ...input,
            },
          };
        },
        invalidatesTags: ["Sync", "Device", "Firm"],
      }),
      getSyncByFirmID: builder.query({
        query: (data) => {
          const { token, ...filteredData } = data;
          const requestData = createRequestData({
            ...filteredData,
          });
          return {
            url: `/firms/getFirmSync`,
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: {
              ...requestData,
            },
          };
        },
        providesTags: ["Sync", "Device"],
      }),
      getDeviceByFirmID: builder.query({
        query: (data) => {
          const { token, ...filteredData } = data;
          const requestData = createRequestData({
            ...filteredData,
          });
          return {
            url: `/firms/getDevicesOfFirm`,
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: {
              ...requestData,
            },
          };
        },
        providesTags: ["Sync", "Device"],
      }),
      distributeFirmQuota: builder.mutation({
        query: (data) => {
          const { token, ...filteredData } = data;
          const requestData = createRequestData({
            ...filteredData,
          });
          return {
            url: `/firms/distributeFirmQuota`,
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: {
              ...requestData,
            },
          };
        },
        providesTags: ["Sync", "Device"],
      }),
      deleteFirmByID: builder.mutation({
        query: (data) => {
          const { token, ...filteredData } = data;
          const requestData = createRequestData({
            ...filteredData,
          });
          return {
            url: `/firms/deleteFirm`,
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: {
              ...requestData,
            },
          };
        },
        providesTags: ["Sync", "Device"],
      }),
      getFirmByID: builder.query({
        query: (data) => {
          const { token, ...filteredData } = data;
          const requestData = createRequestData({
            ...filteredData,
          });
          return {
            url: `/firms/getFirm`,
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: { ...requestData },
          };
        },
        providesTags: ["Firm"],
      }),
      getOtherFirms: builder.query({
        query: ({ id, token }) => {
          return {
            url: `/firms/otherFirms/${id}`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
        providesTags: ["Firm"],
      }),
      getFirmProducts: builder.query({
        query: ({ id, token }) => {
          return {
            url: `/firms/getFirmProducts/${id}`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
        providesTags: ["Firm"],
      }),
      getFirmQuotaByID: builder.query({
        query: ({ id, token }) => {
          return {
            url: `/firms/firmQuota/${id}`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
        providesTags: ["Firm"],
      }),
      getBelongUsersByFirmID: builder.query({
        query: (data) => {
          const { token, ...filteredData } = data;
          const requestData = createRequestData({
            ...filteredData,
          });
          return {
            url: `/firms/belongUsersOfFirm`,
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: {
              ...requestData,
            },
          };
        },
        providesTags: ["Firm"],
      }),
      getBelowFirmsByID: builder.query({
        query: (data) => {
          const { token, ...filteredData } = data;
          const requestData = createRequestData({
            ...filteredData,
          });
          return {
            url: `/firms/belowFirmsOfMainFirm`,
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: {
              ...requestData,
            },
          };
        },
        providesTags: ["Firm"],
      }),
      fetchStorageFirm: builder.query({
        query: (token) => {
          return {
            url: "/firms/getStorageFirms",
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
        providesTags: ["Firm"],
      }),
      getFirm: builder.query({
        query: (data) => {
          const { token, ...filteredData } = data;
          const requestData = createRequestData({
            ...filteredData,
          });
          return {
            url: "/firms/getFirms",
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: { ...requestData },
          };
        },
        providesTags: ["Firm"],
      }),
    };
  },
});

export const {
  useGetFirmQuery,
  useFetchStorageFirmQuery,
  useAddFirmMutation,
  useGetFirmByIDQuery,
  useGetBelowFirmsByIDQuery,
  useUpdateFirmInfoMutation,
  useUpdateFirmSyncMutation,
  useUpdateFirmProductQuotaMutation,
  useUpdateTakeBackFirmProductQuotaMutation,
  useUpdateGiveFirmProductQuotaMutation,
  useDeleteFirmByIDMutation,
  useGetBelongUsersByFirmIDQuery,
  useGetDeviceByFirmIDQuery,
  useGetSyncByFirmIDQuery,
  useDivideQuotaMutation,
  useGetFirmQuotaByIDQuery,
  useGetOtherFirmsQuery,
  useGetFirmProductsQuery,
  useDistributeFirmQuotaMutation,
} = firmApi;
export { firmApi };
