import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import URL from "../url";
import { createRequestData } from "./utils";

const DashBoardApi = createApi({
  reducerPath: "dashBoardDevice",
  baseQuery: fetchBaseQuery({
    baseUrl: `${URL.url}`,
    fetchFn: async (...args) => {
      // REMOVE FOR PRODUCTION
      return fetch(...args);
    },
  }),
  tagTypes: "DashBoardDevice",
  endpoints(builder) {
    return {
      getDashBoardConsuments: builder.query({
        query: (data) => {
          const { token, ...filteredData } = data;
          const requestData = createRequestData({
            ...filteredData,
          });
          return {
            url: `/dashBoard/consuments`,
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: {
              ...requestData,
            },
          };
        },
        providesTags: ["DashBoardDevice"],
      }),
      getDashBoardBills: builder.query({
        query: (data) => {
          const { token, ...filteredData } = data;
          const requestData = createRequestData({
            ...filteredData,
          });
          return {
            url: `/dashBoard/bills`,
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: {
              ...requestData,
            },
          };
        },
        providesTags: ["DashBoardDevice"],
      }),
      getDashBoardDevices: builder.query({
        query: (data) => {
          const { token, ...filteredData } = data;
          const requestData = createRequestData({
            ...filteredData,
          });
          return {
            url: `/dashBoard/devices`,
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
            body: {
              ...requestData,
            },
          };
        },
        providesTags: ["DashBoardDevice"],
      }),
    };
  },
});

export const {
  useGetDashBoardBillsQuery,
  useGetDashBoardConsumentsQuery,
  useGetDashBoardDevicesQuery,
} = DashBoardApi;
export { DashBoardApi };
