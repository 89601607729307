import { useState, ChangeEvent, useEffect } from "react";
import { ThreeDots } from "react-loader-spinner";
import styles from "../../../CustomStyles";
import criticQuotaDevice from "../../../Assets/icons/criticquota_device.png";
import unQuotaDevice from "../../../Assets/icons/unquota_device.png";
import unConnectedDevice from "../../../Assets/icons/unconnected_device.png";
import firmDevice from "../../../Assets/icons/firm_device.png";
import storageDevice from "../../../Assets/icons/storage_device.png";
import playmakerDevice from "../../../Assets/icons/playmaker_device.png";
import serviceDevice from "../../../Assets/icons/service_device.png";

import addedDevice from "../../../Assets/icons/added_device.png";

import { TextField } from "@mui/material";
import DeviceTable from "./DeviceTable";
import { colors } from "../../../Config/Colors";
import { MenuItem } from "@mui/material";

function isThreeDaysApart(date1, date2) {
  // Tarihleri milisaniyeye çevirin
  const timeDifference = Math.abs(new Date(date1) - new Date(date2));

  // Farkı gün cinsine çevirin
  const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

  // 3 güne eşit olup olmadığını kontrol edin
  return daysDifference >= 3;
}

export default function DeviceInfoCard({
  warn,
  devices,
  isLoading,
  isFetching,
}) {
  const [expanded, setExpanded] = useState("criticQuota");
  const [filteredDevices, setFilteredDevices] = useState([]);
  const [filterSerialNo, setFilterSerialNo] = useState("");
  const [filterCount, setFilterCount] = useState({
    playmaker: 0,
    firm: 0,
    storage: 0,
    service: 0,
    unConnected: 0,
    unQuota: 0,
    criticQuota: 0,
  });
  const [filterDeviceType, setFilterDeviceType] = useState("all");
  const [filterFirmName, setFilterFirmName] = useState("");

  const deviceTypes = [
    { value: "all", label: "Tüm Cihazlar" },
    { value: "tea-coffee", label: "Çay-Filtre Kahve Cihazları" },
    { value: "turkish-coffee", label: "Türk Kahvesi Cihazları" },
    { value: "combined", label: "Çay-Filtre Kahve,Türk Kahvesi Cihazları" },
  ];

  useEffect(() => {
    if (devices) {
      let tempDevices = [...devices];

      // Önce expanded filtresini uygula
      if (expanded === "playmaker") {
        tempDevices = tempDevices.filter(
          (device) => device.deviceStatusName === "Plasiyer"
        );
      } else if (expanded === "firm") {
        tempDevices = tempDevices.filter(
          (device) => device.deviceStatusName === "Firma"
        );
      } else if (expanded === "storage") {
        tempDevices = tempDevices.filter(
          (device) => device.deviceStatusName === "Depo"
        );
      } else if (expanded === "service") {
        tempDevices = tempDevices.filter(
          (device) => device.deviceStatusName === "Servis"
        );
      } else if (expanded === "added") {
        tempDevices = tempDevices.filter(
          (device) => device.deviceStatusName === "Üretim"
        );
      } else if (expanded === "unQuota") {
        tempDevices = tempDevices.filter((device) => {
          return device.productInfo.productInfo.some(
            (product) =>
              product.quota <= 0 && device.deviceStatusName === "Firma"
          );
        });
      } else if (expanded === "criticQuota") {
        tempDevices = tempDevices.filter((device) => {
          return device.productInfo.productInfo.some(
            (product) =>
              product.quota <= product.quotaWarning &&
              device.deviceStatusName === "Firma" &&
              product.quota !== 0
          );
        });
      } else if (expanded === "unConnected") {
        const now = new Date();
        tempDevices = tempDevices.filter((device) => {
          if (device.deviceStatusName === "Firma")
            return isThreeDaysApart(device.lastConnectionDate, now);
          return false;
        });
      }

      // Sonra cihaz tipi filtresini uygula
      if (filterDeviceType !== "all") {
        tempDevices = tempDevices.filter((device) => {
          const deviceName = device.deviceName.toLowerCase();
          switch (filterDeviceType) {
            case "tea-coffee":
              return (
                deviceName.includes("çayset") ||
                deviceName.includes("çay-filtrekahve")
              );
            case "turkish-coffee":
              return deviceName === "fincanda";
            case "combined":
              return (
                (deviceName.includes("çayset") ||
                  deviceName.includes("çay-filtrekahve")) &&
                deviceName.includes("fincanda")
              );
            default:
              return true;
          }
        });
      }

      // Serial no filtresini uygula
      if (filterSerialNo !== "") {
        tempDevices = tempDevices.filter((device) =>
          device.serialNo.toLowerCase().includes(filterSerialNo.toLowerCase())
        );
      }

      // Firma adı filtresini uygula
      if (filterFirmName !== "") {
        tempDevices = tempDevices.filter((device) =>
          device.firm.firmName
            .toLowerCase()
            .includes(filterFirmName.toLowerCase())
        );
      }

      setFilteredDevices(tempDevices);
    }
  }, [expanded, filterSerialNo, filterFirmName, devices, filterDeviceType]);

  useEffect(() => {
    if (devices) {
      const filterCount = {
        playmaker: 0,
        firm: 0,
        storage: 0,
        unConnected: 0,
        unQuota: 0,
        criticQuota: 0,
        service: 0,
        added: 0,
      };
      const now = new Date();
      devices.forEach((device) => {
        switch (device.deviceStatusName) {
          case "Plasiyer":
            filterCount.playmaker += 1;
            break;
          case "Firma":
            filterCount.firm += 1;
            break;
          case "Depo":
            filterCount.storage += 1;
            break;
          case "Servis":
            filterCount.service += 1;
            break;
          case "Üretim":
            filterCount.added += 1;
            break;
        }

        if (
          isThreeDaysApart(device.lastConnectionDate, now) &&
          device.deviceStatusName === "Firma"
        ) {
          filterCount.unConnected += 1;
        }
        device.productInfo?.productInfo?.some((product) => {
          let flag = 0;
          if (product.quota <= 0 && device.deviceStatusName === "Firma") {
            filterCount.unQuota += 1;
            flag = 1;
          }
          if (
            product.quota <= product.quotaWarning &&
            device.deviceStatusName === "Firma" &&
            product.quota !== 0
          ) {
            filterCount.criticQuota += 1;
            flag = 1;
          }

          if (flag === 1) return true;
        });
      });

      setFilterCount(filterCount);
    }
  }, [devices]);

  const handleExpand = (id) => {
    setExpanded(id);
    setFilterDeviceType("all");
    setFilterSerialNo("");
    setFilterFirmName("");
  };

  return (
    <div
      className={`
      flex flex-col gap-6 p-6 shadow-lg bg-white
      w-4/6 h-[720px] rounded-2xl
      max-2xl:w-5/6
      max-xl:w-full
      max-lg:h-auto
      max-md:p-4
      ${colors.border.light}
    `}
    >
      {/* Üst Kısım - Filtreler */}
      <div className="flex flex-col gap-4">
        <h2
          className={`text-2xl font-semibold ${colors.text.primary} max-md:text-xl`}
        >
          Cihaz Durumları
        </h2>

        <div className="flex flex-wrap gap-3 max-sm:gap-2">
          {/* Filtre butonları */}
          {[
            {
              id: "criticQuota",
              label: "Kritik Kota",
              color: "bg-red-100 text-red-600",
              icon: criticQuotaDevice,
              count: filterCount.criticQuota,
            },
            {
              id: "unQuota",
              label: "Kotasız",
              color: "bg-orange-100 text-orange-600",
              icon: unQuotaDevice,
              count: filterCount.unQuota,
            },
            {
              id: "unConnected",
              label: "Bağlanmayan",
              color: "bg-yellow-100 text-yellow-600",
              icon: unConnectedDevice,
              count: filterCount.unConnected,
            },
            {
              id: "firm",
              label: "Firma",
              color: "bg-green-100 text-green-600",
              icon: firmDevice,
              count: filterCount.firm,
            },
            {
              id: "storage",
              label: "Depo",
              color: "bg-blue-100 text-blue-600",
              icon: storageDevice,
              count: filterCount.storage,
            },
            {
              id: "playmaker",
              label: "Plasiyer",
              color: "bg-indigo-100 text-indigo-600",
              icon: playmakerDevice,
              count: filterCount.playmaker,
            },
            {
              id: "service",
              label: "Servis",
              color: "bg-purple-100 text-purple-600",
              icon: serviceDevice,
              count: filterCount.service,
            },
            {
              id: "added",
              label: "Üretim",
              color: "bg-pink-100 text-pink-600",
              icon: addedDevice,
              count: filterCount.added,
            },
          ].map((filter) => (
            <button
              key={filter.id}
              onClick={() => handleExpand(filter.id)}
              className={`
                flex items-center gap-2 px-3 py-2 rounded-lg
                max-md:px-2 max-md:py-1.5
                max-sm:text-sm
                ${filter.color}
                transition-all duration-300
                hover:opacity-90
                ${
                  expanded === filter.id
                    ? `ring-2 ring-offset-2 ring-${colors.primary.main}`
                    : ""
                }
              `}
            >
              <img
                src={filter.icon}
                alt={filter.label}
                className="w-6 h-6 max-md:w-5 max-md:h-5"
              />
              <span className="font-medium text-lg max-md:text-base max-sm:text-sm">
                {filter.label}
              </span>
              <span className="font-bold text-lg max-md:text-base max-sm:text-sm">
                {filter.count}
              </span>
            </button>
          ))}
        </div>

        {/* Arama ve Cihaz Tipi Filtreleri */}
        <div className="flex gap-4 items-center flex-wrap max-md:gap-2">
          <TextField
            label="Seri No"
            variant="outlined"
            size="small"
            value={filterSerialNo}
            onChange={(e) => setFilterSerialNo(e.target.value)}
            className="w-48 max-md:flex-1"
            InputProps={{
              className: "bg-gray-50",
            }}
          />

          <TextField
            label="Firma Adı"
            variant="outlined"
            size="small"
            value={filterFirmName}
            onChange={(e) => setFilterFirmName(e.target.value)}
            className="w-48 max-md:flex-1"
            InputProps={{
              className: "bg-gray-50",
            }}
          />

          <TextField
            select
            label="Cihaz Tipi"
            value={filterDeviceType}
            onChange={(e) => setFilterDeviceType(e.target.value)}
            size="small"
            className="w-64 max-md:flex-1"
            InputProps={{
              className: "bg-gray-50",
            }}
          >
            {deviceTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>

      {/* Alt Kısım - Tablo */}
      {isFetching || isLoading ? (
        <div className="flex-1 flex items-center justify-center">
          <ThreeDots
            visible={true}
            height="80"
            width="80"
            color={colors.primary.hex}
            ariaLabel="loading"
          />
        </div>
      ) : !filteredDevices || filteredDevices?.length === 0 ? (
        <div className="flex-1 flex items-center justify-center">
          <p
            className={`text-xl font-medium ${colors.text.primary} p-4 rounded-md`}
          >
            {warn}
          </p>
        </div>
      ) : (
        <DeviceTable
          filterSerialNo={filterSerialNo}
          expanded={expanded}
          data={filteredDevices}
        />
      )}
    </div>
  );
}
