import { useState, useCallback, useMemo, useEffect } from "react";
import {
  useDeleteBillMutation,
  useGetBillsQuery,
  useGetFirmProductsQuery,
  useUpdateGiveFirmProductQuotaMutation,
  useUpdateTakeBackFirmProductQuotaMutation,
} from "../store";

export function useFirmBillsData({ id, token }) {
  const [paginationNumber, setPaginationNumber] = useState(1);
  const [filteredData, setFilteredData] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [alert, setAlert] = useState(0);
  const [message, setMessage] = useState("");

  const {
    data: bills,
    isLoading: billsIsLoading,
    isFetching: billsIsFetching,
    error: billsError,
    refetch: billsRefetch,
  } = useGetBillsQuery(
    { id, token },
    {
      refetchOnMountOrArgChange: true,
      skip: !token || !id,
    }
  );

  const {
    data: productsData,
    isLoading: productsIsLoading,
    error: productsError,
    refetch: productsRefetch,
  } = useGetFirmProductsQuery(
    { id, token },
    {
      refetchOnMountOrArgChange: true,
      skip: !token || !id,
    }
  );

  const [Delete, { isLoading: deleteIsLoading, ...resultDelete }] =
    useDeleteBillMutation();
  const [
    UpdateTakeback,
    { isLoading: takebackIsLoading, ...resultUpdateTakeback },
  ] = useUpdateTakeBackFirmProductQuotaMutation();
  const [UpdateGive, { isLoading: giveQuotaIsLoading, ...resultUpdateGive }] =
    useUpdateGiveFirmProductQuotaMutation();

  const billsData = useMemo(() => bills?.data?.bills || [], [bills]);
  const productDatas = useMemo(() => productsData?.data || [], [productsData]);

  const handleSearch = useCallback((data, isSearch) => {
    setPaginationNumber(1);
    setFilteredData(data);
    setIsSearch(isSearch);
  }, []);

  // API yanıtlarını izle ve alert göster
  useEffect(() => {
    const handleSuccess = (message) => {
      setAlert(1);
      setMessage(message);
      billsRefetch();
      productsRefetch();
      setIsSearch(false);
    };

    const handleError = (error) => {
      setAlert(2);
      setMessage(error?.data?.message || "Bir hata oluştu");
    };

    if (resultUpdateGive.isSuccess) {
      handleSuccess("Kota başarıyla yüklendi");
    } else if (resultUpdateTakeback.isSuccess) {
      handleSuccess("Kota başarıyla geri alındı");
    } else if (resultDelete.isSuccess) {
      handleSuccess("Fatura başarıyla silindi");
    }

    // Hata durumlarını kontrol et
    if (resultUpdateGive.isError) {
      handleError(resultUpdateGive.error);
    } else if (resultUpdateTakeback.isError) {
      handleError(resultUpdateTakeback.error);
    } else if (resultDelete.isError) {
      handleError(resultDelete.error);
    }
  }, [
    resultUpdateGive.isSuccess,
    resultUpdateTakeback.isSuccess,
    resultDelete.isSuccess,
    resultUpdateGive.isError,
    resultUpdateTakeback.isError,
    resultDelete.isError,
    resultUpdateGive.error,
    resultUpdateTakeback.error,
    resultDelete.error,
    billsRefetch,
    productsRefetch,
    setIsSearch,
  ]);

  // Alert'i 3 saniye sonra kaldır
  useEffect(() => {
    if (alert !== 0) {
      const timer = setTimeout(() => {
        setAlert(0);
        setMessage("");
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [alert]);

  const deleteBill = useCallback(
    (deleteData) => {
      Delete(deleteData);
    },
    [Delete]
  );

  const updateGiveQuota = useCallback(
    (quotaData) => {
      UpdateGive({ ...quotaData, note: "", token, id });
    },
    [UpdateGive, token, id]
  );

  const updateTakebackQuota = useCallback(
    (quotaData) => {
      UpdateTakeback({ ...quotaData, note: "", token, id });
    },
    [UpdateTakeback, token, id]
  );

  return {
    billsData,
    billsIsLoading,
    billsIsFetching,
    billsError,
    billsRefetch,
    productDatas,
    productsIsLoading,
    productsError,
    deleteIsLoading,
    takebackIsLoading,
    giveQuotaIsLoading,
    paginationNumber,
    setPaginationNumber,
    filteredData,
    isSearch,
    setIsSearch,
    handleSearch,
    alert,
    setAlert,
    message,
    setMessage,
    deleteBill,
    updateGiveQuota,
    updateTakebackQuota,
    resultDelete,
    resultUpdateGive,
    resultUpdateTakeback,
  };
}
