import { useEffect, useState } from "react";
import {
  useGetDashBoardBillsQuery,
  useGetDashBoardConsumentsQuery,
  useGetDashBoardDevicesQuery,
} from "../store/dashboardApi";

export const useDashboard = (token) => {
  const [dashboardType, setDashboardType] = useState({
    type: "Firma",
    text: "Ürün Tüketimleri",
    graphText: "Yıllık Tüketim Hareketi",
    warn: "Her hangi bir tüketim yok.",
  });

  const [previousData, setPreviousData] = useState({
    devices: null,
    bills: null,
    consuments: null,
  });

  const [activeConsumption, setActiveConsumption] = useState("today");

  const {
    data: dashboardConsumentData,
    isLoading: dashboardConsumentIsLoading,
    isFetching: dashboardConsumentIsFetching,
    error: dashboardConsumentError,
    refetch: dashboardConsumentRefetch,
  } = useGetDashBoardConsumentsQuery(
    { token, section: dashboardType.type },
    {
      refetchOnMountOrArgChange: true,
      skip: false,
    }
  );

  const {
    data: dashboardBillsData,
    isLoading: dashboardBillsIsLoading,
    isFetching: dashboardBillsIsFetching,
    error: dashboardBillsError,
    refetch: dashboardBillsRefetch,
  } = useGetDashBoardBillsQuery(
    { token },
    {
      refetchOnMountOrArgChange: true,
      skip: false,
    }
  );

  const {
    data: dashboardDeviceData,
    isLoading: dashboardDeviceIsLoading,
    isFetching: dashboardDeviceIsFetching,
    error: dashboardDeviceError,
    refetch: dashboardDeviceRefetch,
  } = useGetDashBoardDevicesQuery(
    { token },
    {
      refetchOnMountOrArgChange: true,
      skip: false,
    }
  );

  useEffect(() => {
    if (dashboardBillsData) {
      setPreviousData((prev) => ({ ...prev, bills: dashboardBillsData }));
    }
  }, [dashboardBillsData]);

  useEffect(() => {
    if (dashboardConsumentData) {
      setPreviousData((prev) => ({
        ...prev,
        consuments: dashboardConsumentData,
      }));
    }
  }, [dashboardConsumentData]);

  useEffect(() => {
    if (dashboardDeviceData) {
      setPreviousData((prev) => ({ ...prev, devices: dashboardDeviceData }));
    }
  }, [dashboardDeviceData]);

  useEffect(() => {
    const timer = setInterval(() => {
      dashboardConsumentRefetch();
    }, 5 * 60 * 1000);
    return () => clearInterval(timer);
  }, [dashboardConsumentRefetch]);

  const handleClickConsumption = (choice) => {
    setActiveConsumption(choice);
  };

  const getConsumptionData = () => {
    const data = dashboardConsumentData || previousData.consuments;
    if (!data) return null;

    switch (activeConsumption) {
      case "today":
        return data.todayConsumption;
      case "yesterday":
        return data.yesterdayConsumption;
      case "thisweek":
        return data.thisWeekConsumption;
      case "thismonth":
        return data.thisMonthConsumption;
      default:
        return data.thisYearConsumption;
    }
  };

  return {
    dashboardType,
    setDashboardType,
    activeConsumption,
    isLoading: {
      consuments: dashboardConsumentIsLoading,
      bills: dashboardBillsIsLoading,
      devices: dashboardDeviceIsLoading,
    },
    isFetching: {
      consuments: dashboardConsumentIsFetching,
      bills: dashboardBillsIsFetching,
      devices: dashboardDeviceIsFetching,
    },
    errors: {
      consuments: dashboardConsumentError,
      bills: dashboardBillsError,
      devices: dashboardDeviceError,
    },
    data: {
      consuments: dashboardConsumentData || previousData.consuments,
      bills: dashboardBillsData || previousData.bills,
      devices: dashboardDeviceData || previousData.devices,
    },

    handleClickConsumption,
    getConsumptionData,

    refetch: {
      consuments: dashboardConsumentRefetch,
      bills: dashboardBillsRefetch,
      devices: dashboardDeviceRefetch,
    },
  };
};
