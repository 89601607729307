import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../Hooks/useAuth";
import Loader from "../../Components/Utils/Loader";
import UnfindDataWarn from "../../Components/Utils/UnfindDataWarn";
import TopSearchInfo from "../../Components/SearchBar/TopSearchInfo";
import { useFirmData } from "../../Hooks/useFirmData";
import { colors } from "../../Config/Colors";
import Pagination from "../../Components/Table/Pagination";
import CustomFirmList from "../../Components/Table/CustomFirmList";
import { useDistributeFirmQuotaMutation } from "../../store/firmApi";
import Alerts from "../../Components/Utils/Alert";

function FirmPage() {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [alert, setAlert] = useState({ show: false, type: null, message: "" });

  const {
    firms,
    isLoading,
    isFetching,
    error,
    paginationNumber,
    setPaginationNumber,
    filteredData,
    isSearch,
    handleSearch,
  } = useFirmData(auth.accessToken);

  const [
    distributeFirmQuotaMutation,
    {
      isLoading: distributeFirmQuotaIsLoading,
      isError: distributeFirmQuotaIsError,
      isSuccess: distributeFirmQuotaIsSuccess,
    },
  ] = useDistributeFirmQuotaMutation();

  useEffect(() => {
    if (distributeFirmQuotaIsSuccess) {
      setAlert({
        show: true,
        type: 1,
        message: "Kota başarıyla dağıtılıyor!",
      });
    } else if (distributeFirmQuotaIsError) {
      setAlert({
        show: true,
        type: 2,
        message: "Kota dağıtımı sırasında bir hata oluştu.",
      });
    }

    // Alert'i belirli bir süre sonra kapat
    const timer = setTimeout(() => {
      setAlert({ show: false, type: null, message: "" });
    }, 3000); // 3 saniye sonra kaybolacak

    return () => clearTimeout(timer); // Temizlik işlemi
  }, [distributeFirmQuotaIsSuccess, distributeFirmQuotaIsError]);

  const inputFieldName = {
    firmName: "Firma İsmi",
    mainFirmName: "Ana Firma İsmi",
    bayserNo: "Bayser Numarası",
  };

  return (
    <div className="container px-4 mb-20">
      {alert.show && (
        <div className="fixed z-50 left-1/2 top-30 -translate-x-1/2">
          <Alerts AlertChoice={alert.type} message={alert.message} />
        </div>
      )}

      <TopSearchInfo
        Length={firms.length}
        TopInfo={"Firma Listesi"}
        AddButton={() => navigate("Oluştur")}
        SearchBarData={firms}
        HandleSearch={handleSearch}
        SearchInputFieldName={inputFieldName}
      />

      <div className="flex justify-end mb-4">
        <Pagination
          currentPage={paginationNumber}
          totalCount={firms.length}
          pageSize={10}
          onPageChange={(page) => setPaginationNumber(page)}
        />
      </div>

      {isLoading || isFetching || distributeFirmQuotaIsLoading ? (
        <div className="flex w-full h-[400px] justify-center items-center">
          <Loader
            Visible={true}
            Width={140}
            Height={140}
            Color={colors.primary.hex}
          />
        </div>
      ) : error ? (
        <div className="flex w-full h-[400px] justify-center items-center">
          <UnfindDataWarn Data={"Firma bulunamadı!"} />
        </div>
      ) : (
        <CustomFirmList
          data={isSearch ? filteredData : firms}
          paginationNumber={paginationNumber}
          setPaginationNumber={setPaginationNumber}
          distributeFirmQuota={(firmName) => {
            distributeFirmQuotaMutation({ firmName, token: auth.accessToken });
          }}
        />
      )}
    </div>
  );
}

export default FirmPage;
